import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthRequest } from '../models/auth/authRequest';
import { AuthResponse } from '../models/auth/authResponse';
import { TokenService } from './token.service';
import { RefreshTokenRequest } from '../models/auth/refreshTokenRequest';
import { IdleService } from './idle.service';
import { ForgotPasswordRequest } from '../models/users/forgot.password.request';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;

  constructor(
    private httpService: HttpClient,
    private tokenService: TokenService,
    private idleService: IdleService
  ) { }

  login(authRequest: AuthRequest): Observable<AuthResponse> {
    return this.httpService.post<AuthResponse>(`${this.apiUrl}/user/login`, authRequest)
      .pipe(
        tap(response => {
          this.tokenService.saveToken(response.token);
          this.tokenService.saveRefreshToken(response.refreshToken);
        })
      );
  }

  refreshToken(refreshToken: string) {
    let refreshTokenRequest: RefreshTokenRequest = {
      refreshToken: refreshToken
    }

    return this.httpService.post<AuthResponse>(`${this.apiUrl}/User/refreshtoken`, refreshTokenRequest)
      .pipe(
        tap(response => {
          this.tokenService.saveToken(response.token);
          this.tokenService.saveRefreshToken(response.refreshToken);
          this.idleService.restart();
        })
      );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest) {
    return this.httpService.post(`${this.apiUrl}/User/forgotpassword`, forgotPasswordRequest);
  }
}

import { Routes } from "@angular/router";


export const routes: Routes = [
    {
        path: '',
        redirectTo: '/auth/login', pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.routes').then(m => m.routes)
    },
    {
        path: 'main',
        loadChildren: () => import('./modules/main/main.routes').then(m => m.routes)
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.routes').then(m => m.routes)
    },
    {
        path: 'services',
        loadChildren: () => import('./modules/service-validation/service-validation.routes').then(m => m.routes)
    },
    {
        path: 'users',
        loadChildren: () => import('./modules/users/users.routes').then(m => m.routes)
    },
    {
        path: 'roles',
        loadChildren: () => import('./modules/roles/roles.routes').then(m => m.routes)
    }
]; 

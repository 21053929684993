import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpContext
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { SpinnerService } from '../services/spinner.service';

// Para indicar si se agrega el token o no en el request a enviar al backend
const CHECK_TOKEN = new HttpContextToken<boolean>(() => false);
export function checkToken() {
  return new HttpContext().set(CHECK_TOKEN, true);
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  spinnerSvc = inject(SpinnerService);
  constructor(
    private tokenService: TokenService,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(CHECK_TOKEN)) {
      const isValidToken = this.tokenService.isValidToken(); // accessToken

      if (isValidToken) {
        return this.addToken(request, next);
      }
      else {
        return this.updateAccessTokenAndRefreshToken(request, next);
      }
    }
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.tokenService.getToken();

    if (accessToken) {
      const authRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
      });

      return next.handle(authRequest);
    }

    return next.handle(request);
  }

  private updateAccessTokenAndRefreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const refreshToken = this.tokenService.getRefreshToken();
    const isValidRefreshToken = this.tokenService.isValidRefreshToken();
    if (refreshToken && isValidRefreshToken) {
      return this.authService.refreshToken(refreshToken)
        .pipe(
          switchMap(() => this.addToken(request, next)),
        )
    }

    return next.handle(request);
  }
}
